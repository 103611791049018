import { theme } from 'twin.macro'

const twColor = theme`colors`

export const COLOR_1 = twColor.indigo['600'] 
export const COLOR_2 = twColor.blue['500']
export const COLOR_3 = twColor.emerald['500']
export const COLOR_4 = twColor.amber['500']
export const COLOR_5 = twColor.red['500']
export const COLOR_6 = twColor.purple['500']
export const COLOR_7 = twColor.cyan['500']

export const COLOR_1_LIGHT = twColor.indigo['100'] 
export const COLOR_2_LIGHT = twColor.blue['100']
export const COLOR_3_LIGHT = twColor.emerald['100']
export const COLOR_4_LIGHT = twColor.amber['100']
export const COLOR_5_LIGHT = twColor.red['100']
export const COLOR_6_LIGHT = twColor.purple['100']
export const COLOR_7_LIGHT = twColor.cyan['100']

export const NEW_COLORS = ['#9B2030', '#068993', '#F15922', '#A8876C', '#F2C73C', '#3F6173']


export const SW_COLORS_REVERSE = [
	'#5dcdca', '#3dbac7', '#1fa7c2', '#0a94bb', '#1580b0', '#296ca2', '#385791', '#42437c',
]

export const SW_COLORS = [
	'#42437c', '#385791', '#296ca2', '#1580b0','#0a94bb','#1fa7c2', '#3dbac7', '#5dcdca', 
]

export const SW_TWO_COLORS = [
	'#1c404f', '#1f5968', '#217380', '#288e95', '#37aaa7', '#4ec6b6', '#6ce3c1', '#90ffca'
]

export const SW_TWO_COLORS_REVERSE = [
	'#90ffca', '#6ce3c1', '#4ec6b6', '#37aaa7', '#288e95', '#217380', '#1f5968', '#1c404f', 
]

export const COLORS = [
	COLOR_1,
	COLOR_2,
	COLOR_3,
	COLOR_4,
	COLOR_5,
	COLOR_6,
	COLOR_7
]

export const COLORS_LIGHT = [
	COLOR_1_LIGHT,
	COLOR_2_LIGHT,
	COLOR_3_LIGHT,
	COLOR_4_LIGHT,
	COLOR_5_LIGHT,
	COLOR_6_LIGHT,
	COLOR_7_LIGHT
]